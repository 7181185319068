import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 핀다이렉트 유심 요금제 <완전자유 실속 1GB+> 월 9천원, 동급대비 최저수준",
  "| 지금 구독하면, 1개월 무료. 무료체험 후 5개월 간 최대 1만원 할인 및 데이터 6GB 추가 증정",
  "| 7월부터 이종산업간 다양한 혜택 강화된 제휴요금제 및 프로모션 출시 예정",
]

const content = () => (
  <div>
    <br />
    <div className="descImage">
      <div className="img">
        <img src="/images/press/16/JVJNraXc.jpg" alt="Figure1" />
      </div>
      <div className="desc">△스테이지파이브가 금일(12일) 월 9천원 알뜰폰 무제한 요금제를 출시했다.</div>
    </div>
    <p>
      <br />
      스테이지파이브(대표 서상원)가 12일 (월) 동급 최저가의 월 9천원 알뜰폰 무제한 요금제를 출시했다.
      <br />
      <br />
      스테이지파이브가 출시한 알뜰폰 요금제는 ‘핀다이렉트 유심 완전자유 실속 1GB+’(이하 완전자유 실속 1GB+)이다. 월 9천원의 LTE
      무제한 요금제로, 데이터 1GB 사용 후에는 1Mbps 속도로 무제한 이용할 수 있다. 음성통화 및 문자도 무제한이다. 알뜰폰 요금제라
      약정도 없다.
      <br />
      <br />
      스테이지파이브는 현재 평생 데이터 추가제공 특별 프로모션도 진행 중이다. 지금 가입하면 월 데이터 6GB를 평생 추가로 제공한다.
      <br />
      <br />
      또한 지난 3월부터 진행 중인 스테이지파이브 알뜰폰 요금제 출시 기념 1달 무료 체험 프로모션까지 더하여 고객에 최대 혜택을
      제공한다.
      <br />
      <br />
      지금 가입하면, 7GB의 무제한 요금제를 월 9천원에 이용하는 동시에 첫 한 달은 무료 혜택을 받는 셈이다. 파격 프로모션에 더하여
      요금제 역시 동급 최저 수준이다.
      <br />
      <br />
      합리적 통신 유저 라이프를 제공하는 스테이지파이브는 지난 3월, 동급 초저가로 MVNO(알뜰폰) 5G 및 LTE 유심 요금제(1.4GB~15GB)
      4종을 1만~2만원대 구성으로 출시했다. 이번 완전자유 실속 1GB+ 요금제도 이전과 마찬가지로 합리적 소비를 추구하는 고객들에게
      혜택을 획기적으로 제공하고자 하는 의미에서 마련되었다.
      <br />
      <br />
      이어 7월부터 다양한 제휴 요금제 및 프로모션을 런칭하여 알뜰폰 유저 만족 및 혜택을 강화해 나갈 예정이다. 경품성 이벤트에
      의존하던 기존 업계 프로모션과 달리, 이종산업간 윈윈할 수 있는 요금제를 설계하여 알뜰폰 소비자들에게 차별화된 혜택을 준다는
      전략이다.
      <br />
      <br />
      <br />
    </p>
  </div>
)

const press15 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브 제휴협력실"
      writer="커뮤니케이션팀 pr@stagefive.com "
      date="2021년 7월 12일 즉시 배포 가능"
      pageInfo="*총 2매/ 첨부: 사진 2매"
      title="스테이지파이브, 월 9천원 알뜰폰 무제한 요금제 출시"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press15
